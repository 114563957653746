import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { getPreviousMonthAndYear, getCurrentMonthAndYear } from '@core/utils/filter';

// Notification
import useNotifications from "@notifications"

export default function useDocumentsList() {

    // Use toast
	const { toastNotification } = useNotifications()
	const { month, year } = getCurrentMonthAndYear()

    const refDocumentListTable = ref(null);

	const styleColumns = {
        thClass: 'text-center',
        tdClass: 'text-center'
    }

    // Table Handlers
    let tableColumns = [
		{ key: 'cycle_date', sortable: true, label: 'MES/AÑO', ...styleColumns },
		{ key: 'business_area_name', sortable: true, label: 'RUBRO', ...styleColumns },
        { key: 'is_generated', sortable: true, label: 'REPORTE <br/> GENERADO ',...styleColumns },
        { key: 'document_file', sortable: true, label: 'DESCARGAR <br/> REPORTE', thClass: styleColumns.thClass },
        { key: 'updatedAt', sortable: true, label: 'FECHA <br/> REGISTRO',...styleColumns },
        { key: 'actions', sortable: false, label: 'OPCIONES', ...styleColumns }
    ];

    const perPage = ref(10);
    const totalDocuments = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref('');
    const sortBy = ref('id');
    const isSortDirDesc = ref(true);

    const statesFilter = ref(null);
	const businessAreasFilter = ref(null);
	const monthFilter = ref(month);
	const yearFilter = ref(year);

    const dataMeta = computed(() => {
        const localItemsCount = refDocumentListTable.value ? refDocumentListTable.value.localItems.length : 0;
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalDocuments.value,
        }
    });

    const refetchData = () => {
        refDocumentListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, statesFilter, businessAreasFilter, monthFilter, yearFilter], () => {
        refetchData();
    });

    const fetchDocuments = (ctx, callback) => {
        store
            .dispatch('app-document/fetchDocuments', {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,

                businessAreas: businessAreasFilter.value,
                states: statesFilter.value,
				month: monthFilter.value,
				year: yearFilter.value,
            })
            .then(response => {
                const { documents, total } = response.data;
                callback(documents);
                totalDocuments.value = total;
            })
            .catch(() => {
				toastNotification('Error al obtener lista de documentos', false)
            })
    };

    return {
        fetchDocuments,
        tableColumns,
        perPage,
        currentPage,
        totalDocuments,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refDocumentListTable,

        refetchData,

        // Extra Filters
		statesFilter,
		businessAreasFilter,
		monthFilter,
		yearFilter
    }
}