<template>
	<div>
		<!-- Filters -->
		<documents-list-filters
			v-if="isOptionsLoaded"
			:options="options"
			:business-areas-filter.sync="businessAreasFilter"
			:month-filter.sync="monthFilter"
			:year-filter.sync="yearFilter"
		/>

		<!-- Table Container Card -->
		<b-card
			no-body
			class="mb-0"
		>
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col
						cols="12"
						md="4"
						class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
					>
						<label>Mostrar</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>registros</label>
					</b-col>
				</b-row>
			</div>

			<b-table
				ref="refDocumentListTable"
				class="position-relative table-performances"
				:style="'height: inherit'"
				:items="fetchDocuments"
				responsive
				:fields="tableColumns"
				primary-key="id2"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No se encontraron registros coincidentes"
				:sort-desc.sync="isSortDirDesc"
			>
				<template #table-busy>
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle mb-1"></b-spinner>
						<div>
							<b>CARGANDO</b>
						</div>
					</div>
				</template>

				<template #head(is_generated)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(document_file)="data">
					<span v-html="data.label"></span>
				</template>
				<template #head(updatedAt)="data">
					<span v-html="data.label"></span>
				</template>

				<template #cell(cycle_date)="data">
					<b-link
						v-if="!data.item.locked"
						:to="{ name: 'performances-evaluate', params: { id: data.item.id } }"
						class="font-weight-bold d-block text-nowrap"
					>
						<b-badge variant="light-info">{{ data.item.month }}</b-badge>
					</b-link>
					<b-badge
						v-else
						variant="light-secondary"
					>
						<feather-icon icon="LockIcon" />
						{{ data.item.month }}
					</b-badge>
				</template>

				<template #cell(state_name)="data">
					<b-badge :variant="data.item.state_color">{{ data.item.state_name }}</b-badge>
				</template>

				<template #cell(is_generated)="data">
					<div class="text-nowrap">{{ data.item.has_file ? 'SÍ' : '-'}}</div>
				</template>

				<template #cell(actions)="data">
					<b-dropdown
						variant="link"
						no-caret
						:right="$store.state.appConfig.isRTL"
					>
						<template #button-content>
							<feather-icon
								icon="MoreVerticalIcon"
								size="16"
								class="align-middle text-body"
							/>
						</template>

						<b-dropdown-item :to="{ name: 'documents-generate', params: { id: data.item.id } }">
							<feather-icon icon="EditIcon" />
							<span class="align-middle ml-50">Generar Reporte</span>
						</b-dropdown-item>
					</b-dropdown>
				</template>
			</b-table>

			<div class="mx-2 mb-2">
				<b-row>
					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-start"
					>
						<span
							class="text-muted"
						>Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
					</b-col>

					<b-col
						cols="12"
						sm="6"
						class="d-flex align-items-center justify-content-center justify-content-sm-end"
					>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalDocuments"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
	</div>
</template>

<script>
import useNotifications from "@notifications"
import { formatStringToDate, formatCurrency } from "@core/utils/filter"
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import DocumentsListFilters from "./DocumentsListFilters.vue"
import documentStoreModule from "../documentStoreModule"
import useDocumentsList from "./useDocumentsList"
import { avatarText } from "@core/utils/filter"
import VueSweetalert2 from "vue-sweetalert2"
import vSelect from "vue-select"
import store from "@/store"
import axios from "@axios"
import Vue from "vue"
import "animate.css"

Vue.use(VueSweetalert2)

export default {
	components: {
		DocumentsListFilters,
		vSelect
	},
	setup() {
		// USE TOAST
		const { swalNotification, toastNotification } = useNotifications()

		// REFS
		const options = ref({})
		const isOptionsLoaded = ref(false)

		const DOCUMENT_APP_STORE_MODULE_NAME = "app-document"

		// REGISTER MODULE
		if (!store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME))
			store.registerModule(DOCUMENT_APP_STORE_MODULE_NAME, documentStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME))
				store.unregisterModule(DOCUMENT_APP_STORE_MODULE_NAME)
		})

		// CALL FUNCTION TO POPULATE OPTIONS FOR THE FILTERS
		onBeforeMount(() => {
			getSelectFilters()
		})

		const getSelectFilters = async () => {
			try {
				const [areas, states, months] = await Promise.all([
					axios.get("/selectors/business-areas"),
					axios.get("/selectors/states"),
					axios.get("/selectors/months")
				])
				options.value = {
					...areas.data,
					...states.data,
					...months.data
				}
				isOptionsLoaded.value = true
			} catch (error) {
				toastNotification("Error al cargar datos de selección", false)
			}
		}

		const {
			fetchDocuments,
			tableColumns,
			perPage,
			currentPage,
			totalDocuments,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refDocumentListTable,
			refetchData,

			// EXTRA FILTERS
			statesFilter,
			businessAreasFilter,
			monthFilter,
			yearFilter
		} = useDocumentsList()

		return {
			options,
			isOptionsLoaded,

			fetchDocuments,
			tableColumns,
			perPage,
			currentPage,
			totalDocuments,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refDocumentListTable,
			refetchData,

			// EXTRA FILTERS
			statesFilter,
			businessAreasFilter,
			monthFilter,
			yearFilter,

			// ACTIONS
			avatarText,

			formatStringToDate,
			formatCurrency
		}
	}
}
</script>

<style lang="scss" scoped>
.media {
	align-items: center;
}

.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>